<template>
  <div class="nav" :class="{ fixed: !isOnTop }">
    <img class="nav-logo" src="../assets/petikom_logo.png" alt="logo" />
    <span v-if="!navbarOpen" class="material-icons" @click="openNavbar">
      menu
    </span>
    <span v-if="navbarOpen" class="material-icons" @click="openNavbar">
      close
    </span>
    <ul class="nav-items">
      <li>
        <a href="#">POČETNA</a>
      </li>
      <li>
        <a @click="scrollTo('customs')">CARINJENJE</a>
      </li>
      <li>
        <a @click="scrollTo('internationalTransport')">TRANSPORT I ŠPEDICIJA</a>
      </li>
      <li>
        <a @click="scrollTo('consulting')">KONSALTING</a>
      </li>
      <li>
        <a @click="scrollTo('contact')">KONTAKT</a>
      </li>
    </ul>
    <ul v-if="navbarOpen && isMobile" class="nav-items-mobile">
      <li>
        <a href="#" @click="scrollTo('top')">POČETNA</a>
      </li>
      <li>
        <a @click="scrollTo('customs')">CARINJENJE</a>
      </li>
      <li>
        <a @click="scrollTo('internationalTransport')">TRANSPORT I ŠPEDICIJA</a>
      </li>
      <li>
        <a @click="scrollTo('consulting')">KONSALTING</a>
      </li>
      <li>
        <a @click="scrollTo('contact')">KONTAKT</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOnTop: true,
      navbarOpen: false,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth > 1280 ? false : true;
    }
  },
  methods: {
    openNavbar() {
      this.navbarOpen = !this.navbarOpen;
    },
    scrollTo(payload) {
      if(payload != 'top') this.$emit("handleScrollTo", payload);
      this.navbarOpen = false
    },
  },
  mounted() {
    document.addEventListener("scroll", (e) => {
      if (e.target.scrollingElement.scrollTop === 0) {
        this.isOnTop = true;
        return;
      }
      this.isOnTop = false;
    });
  },
};
</script>

<style scoped lang="scss">
.nav {
  max-width: 1905px;
  position: relative;
  font-family: "Open Sans", sans-serif;
  height: 100px;
  width: 100%;
  background: #fff;
  padding: 20px 190px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  .material-icons {
    display: none;
  }
  .nav-items-mobile {
      display: none;
  }  
  .nav-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    min-width: 650px;
    a {
      cursor: pointer;
      text-decoration: none;
      color: $textBlue;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      transition: all 0.1s ease-in-out;
      &:hover {
        color: $textOrange;
      }
    }
  }
}
.fixed {
  position: fixed;
  opacity: 0.95;
  box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.06);
  height: 100px;
}
@media only screen and (max-width: 1280px) {
  .nav {
    height:60px;
    padding-left: 30px;
    padding-right: 30px;
    img{
      display: block;
      height:200%
    }
    .material-icons {
      display: block;
      cursor: pointer;
    }
    .nav-items {
      display: none;
    }
    .nav-items-mobile {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 100%;
      background: #fff;
      width: 100%;
      z-index: 1000;
      left: 0;
      padding: 50px;
      box-shadow: 0 3px 10px -3px rgba(0, 0, 0, 0.3);
      a {
        cursor: pointer;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        text-transform: capitalize;
        text-decoration: none;
        color: $textOrange;
      }
    }
  }
}
</style>
