<template>
  <div class="wrapper">
    <Navbar @handleScrollTo="handleScrollTo" />
    <Hero @handleScrollTo="handleScrollTo" />
    <PetikomEnterprise />
    <CustomsExportImport :scrollBool="scrollBoolObj.customsBool" />
    <TruckCoverComponent />
    <CustomsAndMediation />
    <InternationalTransport
      :scrollBool="scrollBoolObj.internationalTransportBool"
    />
    <ImageTextFlexComponent
      :imgFirst="true"
      :isGray="false"
      :isConsulting="true"
      imgSrc="truck.png"
      title="Kopneni transport"
      content="Kopneni  transport  
      je vid transporta u kome smo sigurno među vodećim i organizovanijim špedicijama. Vaše je samo da  
      nam javite dimenzije, težinu i adrese utovara i istovara, sve ostalo prepustite nama. Brzina i efikasnost  
      naše su oružje."
      :scrollBool="scrollBoolObj.consultingScrollBool"
    />
    <ImageTextFlexComponent
      :imgFirst="false"
      :isGray="false"
      :isConsulting="true"
      imgSrc="custom.png"
      title="Carinjenje robe"
      content="  
      Usluga gde, u pravom smislu reči, dominiramo. Znanje, iskustvo, kao i dobra priprema, drže naše  
      preduzeće godinama u vrhu. Bez obzira da li je reč o uvozu, izvozu, smeštaju robe u skladište, postupcima  
      privremenog uvoza i izvoza, možete biti sigurni da će sve biti urađeno na najbolji način."
      :scrollBool="scrollBoolObj.consultingScrollBool"
    />
    <ImageTextFlexComponent
      :imgFirst="true"
      :isGray="false"
      :isConsulting="true"
      imgSrc="container.png"
      title="Špedicija"
      content="Organizacija celokupnog  posla počevši  od dogovora šta, kako, sa čim i na koji način, preko organizacije  
      transporta, koji se odvija u različitim vidovima, pa sve do carinjenja Vaših pošiljaka, to je nas svakodnevni  
      posao. Radimo ga tako da Vi budete zadovoljni brzinom i kvalitetom usluge."
      :scrollBool="scrollBoolObj.consultingScrollBool"
    />
    <ImageTextFlexComponent
      :imgFirst="false"
      :isGray="true"
      :isConsulting="true"
      imgSrc="consulting.png"
      title="Konsalting"
      content="Petikom d.o.o. pruža informacije od značaja za prevoz i carinjenje pošiljaka, kako u uvozu, tako i u izvozu. Dakle, kako (kojim putem) je najbolje da pošiljka dođe na odredište, kolike su carinske dažbine kao i sve ono što može da utiče na kvalitet, te brz i siguran transport i konačno carinjenje."
      :scrollBool="scrollBoolObj.consultingScrollBool"
       @handleScrollTo="handleScrollTo"
    />
    <InfiniteSlider />
    <Contact :scrollBool="scrollBoolObj.contactScrollBool" />
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Hero from "./components/Hero.vue";
import PetikomEnterprise from "./components/PetikomEnterprise.vue";
import CustomsExportImport from "./components/CustomsExportImport.vue";
import TruckCoverComponent from "./components/TruckCoverComponent.vue";
import CustomsAndMediation from "./components/CustomsAndMediation.vue";
import InternationalTransport from "./components/InternationalTransport.vue";
import ImageTextFlexComponent from "./components/ImageTextFlexComponent.vue";
import InfiniteSlider from "./components/InfiniteSlider.vue";
import Contact from "./components/Contact.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Hero,
    PetikomEnterprise,
    CustomsExportImport,
    TruckCoverComponent,
    CustomsAndMediation,
    InternationalTransport,
    ImageTextFlexComponent,
    InfiniteSlider,
    Contact,
    Footer,
  },
  data() {
    return {
      scrollBoolObj: {
        contactScrollBool: false,
        consultingScrollBool: false,
        internationalTransportBool: false,
        customsBool: false,
      },
    };
  },
  methods: {
    handleScrollTo(payload) {
      if (payload == "contact") {
        this.scrollBoolObj.contactScrollBool = true;
      }
      if (payload == "consulting") {
        this.scrollBoolObj.consultingScrollBool = true;
      }
      if (payload == "internationalTransport") {
        this.scrollBoolObj.internationalTransportBool = true;
      }
      if (payload == "customs") {
        this.scrollBoolObj.customsBool = true;
      }
      this.$nextTick(() => {
        const keys = Object.keys(this.scrollBoolObj);
        for (const i of keys) {
          this.scrollBoolObj[i] = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.wrapper {
  max-width: 1905px;
  margin: 0 auto;
  overflow: hidden;
}
</style>
