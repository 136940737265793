<template>
  <div class="hero" :class="{ mobileBackground: changeImg }">
    <div class="content-wrap">
      <h1>Petikom D.O.O.</h1>
      <p>kompanija za pružanje usluga u spoljnotrgovinskom prometu, transportu i špediciji.</p>
      <a class="contact-btn" @click="scrollTo('contact')"> KONTAKT </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      changeImg: false,
    };
  },
  methods: {
    scrollTo(payload) {
      this.$emit("handleScrollTo", payload);
    },
  },
  computed: {
    changeBackground() {
      if (window.innerWidth <= 700) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    console.log(window);
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 700) {
        this.changeImg = true;
      } else {
        this.changeImg = false;
      }
    });
  },
};
</script>

<style scoped lang='scss'>
.hero {
  background: url("../assets/petikom_hero_img.jpg") center center no-repeat;
  background-size: cover;
  padding: 230px 190px;
  &.mobileBackground {
    background: url("../assets/truck_mobile.png") no-repeat;
    background-size: cover;
    // background: red;
  }
  .content-wrap {
    width: 650px;
    h1 {
      font-family: Anton;
      font-style: normal;
      font-weight: normal;
      font-size: 72px;
      line-height: 106px;
      color: #fff;
      margin-bottom: 50px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 29px;
      font-family: Open Sans;

      text-align: left;

      color: #ffffff;
    }
    .contact-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 200px;
      height: 67px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      background: $textOrange;
      border: none;
      color: #fff;
      margin-top: 75px;
      cursor: pointer;
    }
  }
}
@media only screen and (max-width: 1280px) {
  .hero {
    padding: 95px 30px 85px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .content-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h1 {
        font-family: Anton;
        font-style: normal;
        font-weight: normal;
        font-size: 53px;
        line-height: 53px;
        text-align: center;
        color: #ffffff;
      }
      p {
        text-align: center;
        font-size: 25px;
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  p {
    margin: 0 30px;
  }
}
@media only screen and (max-width: 500px) {
  p {
    width: 50%;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 350px) {
  p {
    width: 40%;
    font-size: 20px !important;
  }
}
</style>