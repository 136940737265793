<template>
  <div class="footer-wrap" id='footer' ref='footer'>
    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe
          width="100%"
          height="492"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=sime%20igumanova%202&t=&z=15&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
    </div>
    <div class="footer">
      Petikom © 2021.<br />
      All rights have been reserved.
    </div>
  </div>
</template>

<script>
export default {
  
};
</script>

<style lang='scss' scoped>
.footer-wrap {
  position: relative;
  .footer {
    background: rgba(0, 71, 187, 0.8);
    opacity: 0.75;
    height: 75px;
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    color: #ffffff;
  }
}
</style>