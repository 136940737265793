<template>
  <div class="contact" id="contact">
    <div class="scroll" ref="contact"></div>
    <h2>Kontakt</h2>
    <div class="contact-flex-container">
      <div class="contact-flex-container-left">
        <div class="company-info">
          <div class="company-info-icon">
            <img src="../assets/i_icon.png" alt="" />
          </div>
          <h3>Podaci firme</h3>
          <div class="details">Matični broj: <span>20277645</span></div>
          <div class="details">PIB: <span>104945722</span></div>
        </div>
        <div class="company-info">
          <div class="company-info-icon">
            <img src="../assets/phone_icon.png" alt="" />
          </div>
          <h3>Kontakt telefon</h3>
          <div class="details"><a href="tel:+381-63-270-987">+381 63 270 987</a></div>
          <div class="details"><a href="tel:+381-63-277-898">+381 63 277 898</a></div>
        </div>
        <div class="company-info">
          <div class="company-info-icon">
            <img src="../assets/mail.png" alt="" />
          </div>
          <h3>E-mail</h3>
          <div class="details"><span>petikomsped@gmail.com</span></div>
        </div>
        <div class="company-info">
          <div class="company-info-icon">
            <img src="../assets/location_icon.png" alt="" />
          </div>
          <h3>Lokacija</h3>
          <div class="details"><span>Baba Višnjina 9</span></div>
          <div class="details"><span>Sime Igumanova 2-4</span></div>
          <div class="details">
            Carinski terminal Beograd - <span>Prekonoška 8</span>
          </div>
        </div>
      </div>
      <div class="contact-flex-container-right">
        <div class="input-wrap">
          <label for="full-name">Ime i prezime</label>
          <input
            v-model="fullname"
            type="text"
            id="full-name"
            placeholder="Ime i Prezime"
          />
        </div>
        <div class="input-wrap">
          <label for="email">E-mail</label>
          <input
            v-model="email"
            type="text"
            id="email"
            placeholder="E-mail adresa"
            @input="errorMsg = ''"
          />
        </div>
        <div class="input-wrap">
          <label for="phone">Telefon</label>
          <input
            v-model="phone"
            type="text"
            id="phone"
            placeholder="Broj telefona"
          />
        </div>

        <textarea
          @input="errorMsg = ''"
          v-model="message"
          placeholder="Poruka..."
        ></textarea>
        <div class="error-msg">{{ errorMsg }}</div>
        <div class="success-msg">{{ successMsg }}</div>
        <button
          :disabled="inputsFilled"
          @click="recaptcha"
          :class="{ disabledBtn: inputsFilled }"
        >
          POŠALJI
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api'
export default {
  computed: {
    inputsFilled() {
      if (
        this.fullname == "" ||
        this.email == "" ||
        this.phone == "" ||
        this.message == ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    scrollBool(val) {
      if (val) {
        this.$refs.contact.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
  },
  props: {
    scrollBool: {
      type: Boolean,
    },
    data() {
      return {
        fullName: "",
        email: "",
        phone: "",
        message: "",
      };
    },
  },
  data() {
    return {
      fullname: "",
      email: "",
      phone: "",
      message: "",
      token: "",
      errorMsg: "",
      successMsg: "",
    };
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha();
      this.token = token;
      console.log(this.token);
      if (token) {
        this.sendMessage();
      }
    },
    async sendMessage() {
      const obj = {
        fullname: this.fullname,
        email: this.email,
        phone: this.phone,
        message: this.message,
        token: this.token,
      };
      /* eslint no-useless-escape: 0 */
      const mailRegex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isMail = mailRegex.test(this.email);
      if (!isMail) {
        this.errorMsg = "E-mail adresa je nevalidna";
        return;
      }
      if (this.message.length > 500) {
        this.errorMsg = "Tekst poruke mora biti kraći od 500 karaktera";
        return;
      }
      if (
        isMail &&
        obj.fullname != "" &&
        obj.email != "" &&
        obj.phone != "" &&
        obj.message != "" &&
        obj.token != ""
      ) {
        // axios
        //   .post("http://349v122.mars1.mars-hosting.com/send-mail", obj)
        //   .then((response) => {
        //     console.log(response);
            
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
          try {
            const res = await api.sendMail(obj)
            console.log(res.data)
            this.successMsg = "Poruka uspešno poslata!";
            this.fullname = "";
            this.email = "";
            this.phone = "";
            this.message = "";
            setTimeout(() => {
              this.successMsg = ""
            }, 4000);
          } catch (error) {
            console.log(error)
          }
      }
    },
  },
  mounted() {},
};
</script>

<style lang='scss' scoped>
.contact {
  height: 800px;
  padding: 10px 275px;
  font-family: Open Sans;
  .scroll {
    height: 1px;
    width: 100%;
    position: relative;
    top: -150px;
  }
  h2 {
    font-family: Anton;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 70px;
    text-align: center;
    color: #0e2a59;
    margin-bottom: 160px;
    margin-bottom: 70px;
  }
  .contact-flex-container {
    display: flex;
    align-content: center;
    justify-content: center;
    .contact-flex-container-left {
      min-width: 600px;
      min-height: 400px;
      .company-info {
        position: relative;
        min-width: 250px;
        margin-bottom: 40px;
        padding: 10px 40px;
        h3 {
          margin-bottom: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 27px;
          color: #606d80;
        }
        .company-info-icon {
          min-width: 30px;
          min-height: 30px;
          position: absolute;
          top: 50%;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateY(-50%);
        }
        .details {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          color: #8892a1;
          span {
            font-weight: bold;
          }
          a {
            text-decoration: none;
            color: #8892a1;
          }
        }
      }
    }
    .contact-flex-container-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .input-wrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        margin-top: 5px;
        label {
          font-family: Open Sans;
          font-style: italic;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #8892a1;
        }
        input {
          background: #ffffff;
          border: 1px solid #e2e2e2;
          box-sizing: border-box;
          width: 512px;
          height: 42px;
          padding: 12px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: #8892a1;
          transition: all 0.1s;
          &:focus {
            outline: 2px solid #8892a1;
          }
        }
      }
      textarea {
        width: 512px;
        height: 106px;
        background: #ffffff;
        border: 1px solid #e2e2e2;
        box-sizing: border-box;
        padding: 12px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #8892a1;
        transition: all 0.1s;
        resize: none;
        &:focus {
          outline: 2px solid #8892a1;
        }
      }
      .error-msg {
        width: 100%;
        text-align: right;
        font-size: 12px;
        color: #df2d2d;
        font-style: italic;
      }
      .success-msg {
        width: 100%;
        text-align: right;
        font-size: 12px;
        color: #4ceb37;
        font-style: italic;
      }
    }
    button {
      width: 200px;
      height: 67px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.1em;
      color: #ffffff;
      background: $textOrange;
      border: none;
      cursor: pointer;
      margin-left: calc(100% - 200px);
      margin-top: 40px;
      &.disabledBtn {
        background: #cfc8c0;
      }
    }
  }
}
@media only screen and (max-width: 1280px) {
  .contact {
    height: unset;
    padding: 50px 0;
    .contact-flex-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    h2 {
      margin-bottom: 30px;
    }
  }
}
@media only screen and (max-width: 700px) {
  .contact-flex-container-left {
    width: unset;
    height: unset;
    .company-info {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      flex-direction: column !important;
      margin: 40px auto !important;
      max-width: 250px !important;
      .details {
        width: 100%;
      }
      h3 {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  button {
    margin-left: 0 !important;
  }
  input {
    width: unset !important;
  }
  textarea {
    width: 100% !important;
  }
}
</style>