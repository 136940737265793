<template>
  <div class="international-transport" id="international-transport">
    <div class="scroll" ref="internationalTransport"></div>
    <div class="international-transport-left"></div>
    <div class="international-transport-center">
      <p>
        ORGANIZACIJA <br />
        ROBE
      </p>
      <p>
        ŠPEDICIJA <br />
        ROBE
      </p>
      <p>
        TRANSPORT <br />
        ROBE
      </p>
    </div>
    <div class="international-transport-right">
      <h2>Međunarodni transport i špedicija</h2>
      <p>
        Medjunarodni transport i špedicija su ključan deo medjunarodne trgovine.
        Petikom doo je specijalizovan za sve poslove i usluge špedicije u
        oblasti carinskog posredovanja, skladištenja i svih vrsta transporta. U
        zavisnosti od karakteristika robe angažuju se usluge prevoza železnicom,
        kamionom, avionom i pomorskim ili rečnim brodom. Takođe, moguće je i
        kombinovanje različitih načina prevoza robe.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scrollBool: {
      type: Boolean,
    },
  },
  watch: {
    scrollBool(val) {
      if (val) {
        this.$refs.internationalTransport.scrollIntoView({ block: "start" });
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.international-transport {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 100px;
  .scroll {
    position: absolute;
    top: -10%;
  }
  .international-transport-left {
    height: 466px;
    width: 50%;
    background: url("../assets/plane_cargo.png") center center no-repeat;
  }
  .international-transport-right {
    padding: 50px 85px;
    background: $textOrange;
    height: 466px;
    width: 50%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    h2 {
      font-family: Anton;
      font-style: normal;
      font-weight: normal;
      font-size: 48px;
      line-height: 70px;
      color: #fff;
      text-align: center;
    }
    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 130%;
      color: #ffffff;
      margin-top: 20px;
      text-align: center;
    }
  }
  .international-transport-center {
    height: 140px;
    min-width: 1020px;
    position: absolute;
    display: flex;
    top: 90%;
    p {
      width: calc(100% / 3);
      height: 100%;
      background: #f8f8f8;
      box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.06);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: Anton;
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 35px;
      color: $textBlue;
      transition: all 0.1s ease-in-out;
      &:hover {
        color: #fff;
        background-color: #0047bb;
        transform: scale(1.1);
      }
    }
  }
}
@media only screen and (max-width: 1280px) {
  .international-transport {
    align-items: unset;
    flex-direction: column-reverse;
    height: unset;
    .scroll {
      position: absolute;
      bottom: 100%;
    }
    .international-transport-left {
      width: 100%;
      background-size: cover;
    }
    .international-transport-center {
      position: static !important;
      height: unset;
      min-width: unset;
      position: fixed;
      display: flex;
      flex-direction: column;
      p {
        width: 100%;
        padding: 15px 50px;
        background: #f8f8f8;
        box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.06);
        justify-content: center;
        color: #010C71;
        &:hover {
          color: initial;
          background-color: initial;
          transform: initial;
          background: #0047BB;
          color: #f8f8f8;
        }
      }
      // p:nth-child(1) {
      //   background: #f8f8f8;
      //   box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.06);
      //   justify-content: flex-start;
      // }
      // p:nth-child(2) {
      //   background: #f8f8f8;
      //   box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.06);
      //   justify-content: center;
      //   background: #0047bb;
      //   color: #fff;
      // }
      // p:nth-child(3) {
      //   background: #f8f8f8;
      //   box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.06);
      //   justify-content: flex-end;
      // }
    }
    .international-transport-right {
      background: #f5921e;
      padding: 30px 30px;
      height: unset !important;
      width: 100%;
      h2 {
        text-align: center;
      }
      p {
        text-align: center;
        margin-top: 25px;
      }
    }
  }
}
</style>