<template>
  <div class="customs-mediation">
    <div class="customs-mediation-left">
      <h2>Carinsko posredovanje, aktuelne tarife i zakoni</h2>
      <p class="customs-tarifs">
        Carinske tarife možete pogledati <a href="static/uredba.pdf" target="__blank">ovde.</a>
      </p>
    </div>
    <div class="customs-mediation-right">
      <div class="flex-item">
        <div class="docs-icon"></div>
        <p>DOKUMENTACIJA ZA CARINSKI POSTUPAK</p>
      </div>
      <div class="flex-item">
        <div class="box-icon"></div>
        <p>PRIJEM I SKLADIŠTENJE ROBE</p>
      </div>
      <div class="flex-item">
        <div class="supplies-icon"></div>
        <p>OTPREMA ROBE</p>
      </div>
      <div class="flex-item">
        <div class="customs-icon"></div>
        <p>CARINSKO POSREDOVANJE</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang='scss' scoped>
.customs-mediation {
  min-height: 800px;
  padding: 70px 190px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  .customs-mediation-left {
    // margin-top: 300px;
    h2 {
      font-family: Anton;
      font-style: normal;
      font-weight: normal;
      font-size: 48px;
      line-height: 70px;
      color: $textBlue;
      margin-bottom: 50px;
      text-align: center;
    }
    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 200%;
      color: $textGray;
      text-align: center;
      a {
        text-decoration: underline;
        color: $textBlue;
        font-style: italic;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .customs-mediation-right {
    height: 462px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    .flex-item {
      width: 300px;
      height: 250px;
      background: #fff;
      box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.06);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
      font-family: Anton;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 38px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #aaaaaa;
      &:hover {
        transition: all 0.1s ease-in-out;
        background: #0047bb;
        color: #fff;
      }
      p {
        margin-top: 50px;
      }
      div {
        width: 80px;
        height: 80px;
      }
      .docs-icon {
        background: url("../assets/docs.svg") center center no-repeat;
      }
      .box-icon {
        background: url("../assets/box.svg") center center no-repeat;
      }
      .supplies-icon {
        background: url("../assets/supplies.svg") center center no-repeat;
      }
      .customs-icon {
        background: url("../assets/customs.svg") center center no-repeat;
      }
    }
    .flex-item:nth-child(1) {
      &:hover {
        .docs-icon {
          background: url("../assets/docs_white.svg") center center no-repeat;
        }
      }
    }
    .flex-item:nth-child(2) {
      &:hover {
        .box-icon {
          background: url("../assets/box_white.svg") center center no-repeat;
        }
      }
    }
    .flex-item:nth-child(3) {
      &:hover {
        .supplies-icon {
          background: url("../assets/supplies_white.svg") center center
            no-repeat;
        }
      }
    }
    .flex-item:nth-child(4) {
      &:hover {
        .customs-icon {
          background: url("../assets/customs_white.svg") center center no-repeat;
        }
      }
    }
  }
}
@media only screen and (max-width: 1550px) {
  .customs-mediation {
    flex-direction: column;
    align-items: center;
    .customs-mediation-left {
      margin-top: 100px;
      width: 100%;
      h2 {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
    .customs-mediation-right {
      margin-bottom: 100px;
      /* flex-direction: column; */
      height: unset;
      width: 75%;
      flex-wrap: wrap;
      .flex-item {
        margin: 20px;
        // transform: scale(0.9);
      }
    }
  }
}
@media only screen and (max-width: 1280px) {
  .customs-mediation {
    padding: 0;
    margin: 50px 0 80px 0;
    align-items: center;
    // flex-direction: column;
    .customs-mediation-left {
      margin: 0;
      h2 {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
    .customs-mediation-right {
      margin: 0;
      height: unset;
      // flex-direction: column;
      .flex-item {
        margin: 20px;
      }
    }
  }
}
@media only screen and (max-width: 770px) {
  .customs-mediation {
    flex-direction: column !important;
    .customs-mediation-right {
      width: 100% !important;
      .flex-item {
        margin: 20px 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .flex-item {
    transform: scale(0.8);
    margin: 0;
  }
}
</style>