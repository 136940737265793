<template>
  <div
    :id="isConsultingTrue"
    class="image-text-flex-component"
    :class="{ 'inverted-flex': !imgFirst, 'gray-background': isGray }"
  >
    <div class="scroll" ref="dynamicComponent"></div>
    <div class="img-container">
      <img v-if="imgSrc" :src="require('../assets/' + imgSrc + '')" />
    </div>
    <div
      class="text-container"
      :class="{ 'margin-left': imgFirst, 'margin-right': !imgFirst }"
    >
      <h2 v-if="title">
        {{ title }}
      </h2>
      <p v-if="content">
        {{ content }}
      </p>
      <button @click="scrollTo('contact')" v-if="isGray">KONTAKT</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    imgFirst: {
      type: Boolean,
      default: true,
    },
    isGray: {
      type: Boolean,
      default: false,
    },
    isConsulting: {
      type: Boolean,
      default: false,
    },
    scrollBool: {
      type: Boolean,
    },
  },
  computed: {
    isConsultingTrue() {
      if (this.isConsulting) {
        return "consulting";
      } else {
        return "";
      }
    },
  },
  watch: {
    scrollBool(val) {
      if (val) {
        this.$refs.dynamicComponent.scrollIntoView({ block: "start" });
      }
    },
  },
  methods: {
    scrollTo(payload) {
      this.$emit("handleScrollTo", payload);
    },
  },
};
</script>

<style lang='scss' scoped>
.image-text-flex-component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 80px 190px;
  .scroll {
    position: absolute;
    height: 10px;
    top: -100px;
  }
  .text-container {
    width: 700px;
    h2 {
      font-family: Anton;
      font-style: normal;
      font-weight: normal;
      font-size: 48px;
      line-height: 70px;
      color: $textBlue;
      margin-bottom: 30px;
      text-align: left;
    }
    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 190%;
      color: $textGray;
      text-align: left;
    }
  }
  button {
    width: 151px;
    height: 50px;
    background: #f5921e;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    cursor: pointer;
    margin-top: 20px;
  }
  .img-container {
    img {
      display: block;
      width: 100%;
    }
  }
}
.inverted-flex {
  flex-direction: row-reverse;
}
.gray-background {
  background: #f8f8f8;
}
.margin-left {
  margin-left: 50px;
}
.margin-right {
  margin-right: 50px;
}
@media only screen and (max-width: 1280px) {
  .image-text-flex-component {
    flex-direction: column-reverse;
    padding: 50px 0 0 0;
    justify-content: center;
    text-align: center;
    margin: 50px 0 75px 0;
    width: 100%;
    .text-container {
      margin-bottom: 50px;
      p,
      h2 {
        text-align: center;
      }
    }
  }
  .margin-left {
    margin: 0 30px;
  }
  .margin-right {
    margin: 0 30px;
  }
}

@media only screen and (max-width: 700px) {
  .text-container {
    width: unset !important;
  }
  .img-container {
    margin: 0 auto;
    width: 85%;
  }
}
</style>